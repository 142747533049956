.loginScreen {
	display: flex;
	align-items: center;
	justify-content: center;

	&__form {
		width: 100%;
		max-width: 54rem;
		padding: 55px 90px;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
		border-radius: 6px;

		h2 {
			font-size: 30px;
			font-weight: 500;
		}

		input[type="text"], input[type="password"] {
			display: block;
			width: 100%;
			margin-top: 40px;
			padding: 5px 10px;
			color: #3d3d3d;
			font-size: 18px;
			font-weight: normal;
			border: none;
			border-bottom: 1px solid #ccc;
			outline: none;

			&:active {
				outline: none;
			}
		}
		input[type="submit"] {
			width: 100%;
			padding: 12px 10px;
			margin-top: 40px;
			color: #fff;
			font: normal bold 20px "Open Sans";
			border-radius: 3px;
			background: linear-gradient(to right, #71afce, #3889e0) no-repeat center;
		}
	}
}