.addCoordinatesScreen {
  flex-direction: column;
  .screen__header {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .screen__content {
    margin: auto;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-top: 10px;
  }

  &__form {
    margin-top: 20px;
  }

  &__locationDropdown {
    width: 100%;
  }

  &__submit {
    margin-top: 20px;
    max-width: initial !important;
  }
}