.homeFooter {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: $maxWidth;

	&__card {
		flex: 1;
		height: 130px;
		position: relative;
		margin-right: 20px;
		margin-bottom: 20px;
		box-shadow: 0 0 15px 0 rgba(51,153,255,0.25);
		background-color: #fff;
		background-position: 20px 46px;
		background-repeat: no-repeat;
		background-size: 86px;

		&Value {
			min-height: 15px;
			margin: 50px 0 0 120px;
			padding-right: 10px;
			font-size: 32px;
			font-weight: bold;
		}
		&Label {
			margin: 10px 0 0 120px;
			padding-right: 10px;
			font-size: 12px;
			color: #c5c9cc;
		}

		&Loader {
			position: absolute;
			top: 2px;
			right: 4px;
		}

		&HelpIcon {
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 6px;
			right: 6px;
			padding-left: 1px;
			font-weight: bold;
			font-size: 11px;
			text-align: center;
			line-height: 16px;
			background: #eee;
			color: #555;
			border-radius: 50%;
			cursor: pointer;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				+ .homeFooter__cardHelpContent {
					display: flex;
				}
			}
		}
		&HelpContent {
			display: none;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 25px;
			right: 18px;
			padding: 8px;
			font-size: 12px;
			line-height: 16px;
			background: #fff;
			color: #494949;
			box-shadow: 0 0 4px rgba(0,0,0,0.2);
			z-index: 99;
		}

		&--graph1 {
			background-image: url('#{$imagesPath}/icons/graph-1.png');
		}
		&--graph2 {
			background-image: url('#{$imagesPath}/icons/graph-2.png');
		}
		&--graph3 {
			background-image: url('#{$imagesPath}/icons/graph-3.png');
		}
		&--disabled, &--loading {
			filter: grayscale(1);
			cursor: default;
			opacity: 0.7;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			.homeFooter__cardLabel, .homeFooter__cardValue {
				opacity: 0.7;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@include size(xl) {
		&__card {
			height: 120px;
			margin: 0 0 20px 20px;
			background-size: 70px;
			background-position: 30px 40px;

			&Value {
				font-size: 28px;
				margin-top: 40px;
			}

			&:last-child {
				margin: 0 20px 0 20px;
			}
		}
	}
	@include size(l) {
		&.homeFooter { margin-top: 10px; }
		&__card {
			height: 110px;
			margin: 0 0 10px 10px;
			background-size: 70px;
			background-position: 30px 40px;

			&Value {
				font-size: 24px;
				margin-top: 40px;
			}
			&Label {
				margin-top: 2px;
				font-size: 10px;
			}
			&:last-child {
				margin-left: 10px;
				margin-right: 10px;
			}
		}
	}
	@include size(m) {
		flex-direction: column;
		&__card {
			height: 90px;
			margin: 0 10px 10px;
			background-size: 50px;
			background-position: 20px 34px;

			&Value {
				margin-left: 90px;
				margin-top: 30px;
			}
			&Label {
				margin-left: 90px;
				margin-top: 2px;
				margin-bottom: 20px;
			}

			&:last-child {
				margin: 0 10px 10px;
			}
		}
	}
	@include size(s) {

		&__card {
			height: 80px;
			background-size: 50px;
			background-position: 20px 24px;

			&Value {
				font-size: 14px;
				margin-top: 20px;
			}
			&Label {
			}
		}
	}
}
