.package {
	width: 320px;
	margin: 0 15px;

	&__contentTop, &__contentBottom {
		position: relative;
		padding: 40px;
		background: #fff;
		border-radius: 0 0 44px 44px;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
	}

	&__contentTop {
		text-align: center;
		min-height: 380px;
		padding-top: 130px;
		z-index: 1;
	}
	&__contentBottom {
		min-height: 290px;
		margin-top: -50px;
		padding-top: 50px;
	}

	&__title, &__subtitle {
		width: 100%;
		align-items: center;
	}
	&__title {
		font-size: 28px;
		strong {
			font-weight: bold;
		}
	}
	&__subtitle {
		margin-top: 8px;
		font-size: 16px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	&__description {
		margin-top: 30px;
		color: #9d9d9d;

		strong {
			font-weight: bold;
			color: #6a25f2;
		}
	}
	&__currency {
		margin-top: 20px;
		color: $primary;
		font-weight: bold;
		font-size: 18px;

		&Price {
			position: relative;
		}

		&Price, &NewPrice {
			display: inline-block;
		}

		&NewPrice {
			margin-left: 10px;
		}

		&--discount {
			.package__currencyPrice {
				font-weight: normal;
				font-size: 16px;
				color: #bbb;

				&::after {
					content: "";
					display: block;
					height: 2px;
					width: 92px;
					position: absolute;
					top: 3px;
					left: 0;
					transform-origin: top right;
					transform: rotate(-8deg);
					background: darkred;
				}
			}
		}
	}
	&__footerNote {
		color: #999;
		font-size: 1.2rem;
		line-height: 1.4rem;
		position: relative;
		top: 10px
	}
	.button {
		margin-top: 10px;
		position: relative;

		&:hover {
			background-color: $primary;
			color: $white;

			.button__text {
				display: none;
			}
			.button__textHover {
				display: block !important;
			}
		}

		&__textHover {
			display: none;
		}

		&__icon {
			content: "";
			display: none;
			width: 28px;
			height: 28px;
			position: absolute;
			top: -12px;
			right: -12px;
			background-color: $white;
			border: 2px solid $primary;
			border-radius: 50%;
			box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 70%;
				filter: $primaryFilter
			}

			&--verified {
				&::after {
					background-image: url('#{$imagesPath}/icons/verified.svg');
				}
			}
			&--abort {
				&::after {
					background-image: url('#{$imagesPath}/icons/close.svg');
					background-size: 60%;
				}
			}
		}
	}

	&--active {
		.button {
			background-color: $primary;
			color: $white;
			text-transform: uppercase;

			&:hover {
				background: $red;
				border-color: darken($red, 10);
				text-transform: initial;
				.button__icon--verified {
					display: none;
				}
				.button__icon--abort {
					display: block;
					border-color: $red;

					&::after {
						filter: $redFilter;
					}
				}
			}

			&__icon--verified {
				display: block;
			}
		}
	}

	&--small {
		margin-top: 40px;
		border-top: 15px solid #177fb3;
		.package__contentTop {
			background: #fff url('../../images/icons/package-small.png') no-repeat center 3%;
			padding-bottom: 26px;
		}
	}
	&--medium {
		border-top: 15px solid #196684;
		.package__contentTop {
			min-height: 420px;
			background: #fff url('../../images/icons/package-medium.png') no-repeat center 3%;
			.button {
				margin-top: 10px;
			}
			.package__description {
				height: 65px;
			}
			.package__title {
				margin-top: 10px;
			}
		}
	}
	&--large {
		margin-top: 40px;
		border-top: 15px solid #003c5d;
		.package__contentTop {
			background: #fff url('../../images/icons/package-large.png') no-repeat center 3%
		}
	}

	&--preview {
		width: 260px;
		margin-top: 0;

		.package__contentTop {
			min-height: 260px;
			padding-top: 110px;
			background-position: center 0;
		}
		.package__title {
			font-size: 20px;
		}
		.package__subtitle {
			margin-top: 2px;
			font-size: 12px;
		}
		.package__description {
			font-size: 12px;
		}
	}
}