.pills {
	display: inline-flex;
	border: 1px solid #2674a8;
	border-radius: 5px;
	align-self: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&__option {
		padding: 8px 36px;
		font: normal 600 14px Roboto;
		color: #2674a8;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;

		&--active {
			background-color: #2674a8;
			color: #fff;
			cursor: default;
		}

		&:hover {
			color: lighten(#2674a8, 20);

			&.pills__option--active {
				color: #fff;
			}
		}
	}
}