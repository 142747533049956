.homeScreen {
	height: auto;
	display: block;
	flex: initial;
	min-height: auto;

	.screen__content {
		height: auto;
		min-height: auto;
		margin: 24px auto 0;
		flex-direction: row;
		padding: 0;
		position: relative;
		overflow: initial;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 20px;
			box-shadow: 0 0 20px 0 rgba(51, 153, 255, 0.25);
			pointer-events: none;
		}
	}

	.homeFooter {
		margin: 20px auto 0;
	}

	.chart {
		width: 100%;
		max-width: 900px;
		height: 100%;
		background: #fff;
		z-index: 8;
		position: relative;

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			box-shadow: -10px 0 10px 0 rgba(51, 153, 255, 0.1) inset;
			left: 0;
		}

		.customDatePicker {
			display: none;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 70px;
			right: 58px;
			z-index: 999;
			background: #fff;

			&__actions {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				width: 100%;
			}

			&__submit,
			&__cancel {
				width: 100%;
				height: 40px;
				max-width: 200px;
				margin: 0 10px 10px;
				padding: 10px 0;
				background-color: #0066a1;
				border-radius: 3px;
				font: normal bold 16px "Open Sans";
				color: #fff;
			}
			&__cancel {
				background-color: #aaa;
			}

			&--show {
				display: flex;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			height: 70px;
			position: relative;
			z-index: 9;

			&Label {
				display: flex;
				flex-direction: column;
				flex: 1;
				margin-left: 30px;
				color: #0066a1;
			}
			&Title {
				font-size: 20px;
				font-weight: bold;
			}
			&Subtitle {
				font-size: 12px;
			}
			.dropdown {
				margin-right: 60px;
			}
			.dateIncrementor {
				margin-right: 60px;
			}
			.dateType {
				margin-right: 60px;
			}
		}
	}

	.screen__sidebar {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 0;
		width: 300px;
		background: #f4fbff;
		position: relative;
		padding: 20px;
		z-index: 9;

		.sensors {
			margin-top: 40px;
		}
		.bookBtnWrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			flex: 1;
			justify-content: flex-end;
		}
		.bookBtn {
			margin-top: 30px;
			width: 100%;
			max-width: 300px;
			padding: 10px 0;
			background-color: #0066a1;
			border-radius: 3px;
			font: normal bold 16px "Open Sans";
			color: #fff;
		}

		.bookBtnNote {
			margin-top: 6px;
			text-align: center;
			color: #6b6b6b;
			font: normal 200 12px "Open Sans";
			visibility: hidden;

			&--show {
				visibility: visible;
			}
		}
	}

	&--campaign-stats {
		.screen__sidebar {
			.bookBtn {
				margin-top: 20px;
				margin-bottom: 6px;
				padding: 8px 0;
				background: $primary;
			}
		}
	}

	&--hide-transactions {
		.screen__sidebar {
			.bookBtn {
				margin-top: 30px;
				margin-bottom: 6px;
				padding: 8px 0;
				background: $primary;
			}
		}
	}

	&--minimized-layer-panel {
		padding-bottom: 20px;

		.chart {
			max-width: 1000px;
		}
		.screen__sidebar {
			width: 200px;
		}
		@include size(xl) {
			.sensors {
				justify-content: center;
				flex-direction: row;
			}
		}

	}

	@include size(xl) {
		.screen__content {
			flex-direction: column;
			align-items: center;
			margin-top: 0;
			background: #2965a3;

			&::before {
				border-radius: 0;
			}

			.chart {
				margin: 20px;
				max-width: none;
			}

			.screen__sidebar {
				width: 100%;
				box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);

				.sensors {
					margin-top: 0;
				}
			}
		}
	}

	@include size(m) {
		.screen__content {
			.chart {
				&__header {
					justify-content: space-evenly;
					&Label {
						display: none;
					}
				}
				.dropdown {
					margin: 0 10px;
				}
				.dateIncrementor {
					margin-right: 0;
					&__value {
						padding: 0;
					}
				}
				.dateType {
					margin: 0 10px;
				}
			}
		}
	}
}
