.imageUpload {
	position: relative;

	&__label {
		margin-bottom: 10px;
		font: normal normal 14px Roboto;
		color: #0066a1;
		text-align: center;
	}
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px;
		width: 140px;
		height: 120px;
		position: relative;
		background-size: cover;
		background-position: center;
		background-color: #f5fcff;
		border: 1px dashed #b9b9b9;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;

		.imageUpload__cta {
			display: block;
			font: normal 600 13px Roboto;
			width: 100%;
			padding: 8px 12px;
			text-align: center;
			background: #fff;
			border: 1px solid #2674a8;
			border-radius: 4px;
			color: #2674a8;
		}

		input {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
			-webkit-tap-highlight-color: transparent;
		}

		&:hover {
			opacity: 0.7;
		}
	}

	&__loader {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(255,255,255,0.8);
		z-index: 1;
		pointer-events: none;

		&Label {
			margin-top: 10px;
			font: normal normal 12px Roboto;
			text-transform: uppercase;
			color: #4a4a4a;
			text-shadow: 0 1px #fff;
		}
	}

	&__verified {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 6px;
		bottom: 6px;
		border-radius: 50%;
		background-color: rgba(0,0,0,0.5);
		pointer-events: none;

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: url('#{$imagesPath}/icons/verified.svg') no-repeat center;
			background-size: 70%;
			filter: invert(1);
		}
	}

	&--active {
		.imageUpload__cta {
			display: none;
			color: #fff;
			background-color: transparent;
			border: none;
			text-shadow: 0 0 3px #000, 0 0 5px #fff, 0 0 5px #fff;
		}

		&:hover {
			.imageUpload__content {
				opacity: 0.9;
				filter: blur(1.4) contrast(1.3);
			}
			.imageUpload__cta {
				display: block;
			}
		}
	}
	&--error {
		.imageUpload {
			&__label {
				color: $red;
			}
			&__content {
				border-color: $red;
			}
			&__cta {
				border-color: $red;
				color: $red;
			}
		}
	}
}