.campaignDetailsScreen {
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;

	.screen__content {
		align-items: center;
		padding-top: 0;
	}

	&__top {
		display: none;
		width: 100%;
		background-color: #2674a8;
		padding: 0 20px 30px;
		color: #fff;
		box-shadow: 0 5px 10px 0 rgba(0,0,0,0.075);
	}

	&__goBack {
		width: 100%;
		position: relative;
		padding-left: 30px;
		margin-top: 30px;
		color: $primary;
		text-transform: uppercase;
		font-size: 12px;
		cursor: pointer;

		&:hover {
			color: lighten($primary, 20);
		}

		&::before {
			content: "";
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 0;
			left: 0;
			background: url('#{$imagesPath}/icons/back.svg') no-repeat center;
			background-size: 100%;
			filter: $primaryFilter;
		}
	}

	&__title {
		font: normal bold 24px Roboto;
		text-align: center;
		margin: 30px 0 0;

		&--large {
			margin-top: 20px;
			font-size: 30px;
		}
	}

	&__subtitle, &__description {
		font: normal normal 16px Roboto;
		text-align: center;
		margin: 10px 0 0;
	}
	&__description {
		margin-top: 24px;
	}

	&__submitSuccess, &__submitError {
		font-family: Roboto;
		color: $green;
		margin-bottom: 10px;
	}
	&__submitError {
		color: $red;
	}

	.packageDetails {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
		flex-shrink: 0;

		&__change {
			position: absolute;
			top: 4px;
			right: 8px;
			font-size: 12px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.package {
			flex-shrink: 0;
		}

		.packageInfo {
			margin: 0 0 0 50px;

			.checklist {
				margin-top: 0;

				&__item {
					margin: 22px 0;
				}
			}

			h1 {
				position: relative;
				padding: 0 0 20px;
				color: #505050;
				font: normal bold 24px Roboto;

				&::after {
					content: "";
					display: block;
					height: 6px;
					width: 48px;
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: #196684;
				}
			}
		}
	}
	.landingUrl	{
		display: flex;
		align-items: flex-start;
		flex-shrink: 0;
		width: 100%;
		margin: 20px 0 0 0;
		position: relative;

		&__label {
			display: inline-flex;
			color: #0066a1;
			font: normal normal 12px Roboto;
			position: absolute;
			top: -16px;
		}
		&__input {
			width: 200px;
			font: normal 600 13px Roboto;
			padding: 8px 12px;
			background: #fff;
			border: 1px solid #2674a8;
			border-radius: 4px;
			color: $primary;
			outline: none;
		}
		&__previewLink {
			font-size: 12px;
			margin-left: 10px;
			display: inline-block;
			text-decoration: none;
			position: absolute;
			top: -2px;
			left: 196px;
		}
		&__submit {
			align-self: center;
			padding: 6px 30px;
			margin-left: 20px;
			font-size: 14px;
			text-transform: none;
		}
	}
	.packageCreative {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		margin-top: 40px;
		padding-bottom: 20px;

		.creativePreview {
			display: flex;
			flex-direction: column;

			&__title {
				color: #505050;
				font: normal bold 16px Roboto;
				text-transform: capitalize;
			}
			&__content {
				width: 430px;
				height: 250px;
				position: relative;
				margin-top: 20px;
				background: #fff;
				box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.07);

				&Default, &Custom {
					display: none;
					flex-direction: column;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;

					&--show {
						display: flex;
					}

					&--loading {
						animation: graySignal 2s infinite;
					}
				}

				&Message {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 20px;
					text-align: center;
					font: normal bold 18px "Open Sans";
				}
				iframe {
					position: relative;
					z-index: 9;
				}
			}
			.pills {
				margin-top: 20px;
			}
			&__upgradeLink {
				position: absolute;
				bottom: -22px;
				right: 0;
				font-style: oblique;
				text-decoration: none;
				color: $primary;
				font-size: 12px;
				&:hover {
					text-decoration: underline;
				}
			}
			&__loaderWrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
			}
			&__loaderCircle {

			}
		}
		.creativeBuild {
			margin-left: 46px;
			flex: 1;

			&__toggler {
				display: none;
				width: 100%;
				height: 100%;
				align-items: center;
				justify-content: center;
				flex: 1;

				&Text {
					position: relative;
					font: normal bold 24px Roboto;
					color: #c5c5c5;
					margin-left: 40px;
					cursor: default;
					user-select: none;
					text-transform: uppercase;

					&::before, &::after {
						content: "";
						display: block;
						height: 1px;
						width: 25px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						background-color: #c5c5c5;
					}
					&::before {
						left: -40px;
					}
					&::after {
						right: -40px;
					}
				}

				&Button {
					padding: 20px 25px;
					margin-left: 120px;
					color: #a9a9a9;
					background-color: #f7f7f7;
					font: normal bold 24px Roboto;
					border-radius: 10px;
					cursor: pointer;
					-webkit-tap-highlight-color: transparent;
					text-align: center;

					&:hover {
						background-color: $primary;
						color: #fff;
					}
				}
			}
			&__title {
				color: #505050;
				font: normal bold 16px Roboto;

				a {
					color: $primary;
					font-size: 12px;
					font-weight: normal;
					position: relative;
					top: 2px;
					left: 3px;
					text-decoration: none;
					vertical-align: top;
					&:hover {
						text-decoration: underline;
						color: lighten($primary, 10);
					}
				}
			}
			&__content {
				display: flex;
				flex-direction: column;
				.creativeBuild__uploadText {
					margin-top: 18px;
					color: #0066a1;
					font: normal normal 16px Roboto;

					span {
						display: block;
						font-size: 12px;
						line-height: 14px;
						color: #999999;
					}
				}

				.creativeBuild__assets {
					display: flex;
					margin-top: 14px;

					.imageUpload {
						margin-left: 20px;

						&:first-child {
							margin-left: 0;
						}
					}
				}
				.creativeBuild__submit {
					align-self: center;
					max-width: 280px;
					height: 40px;
					padding: 8px 30px;
					margin-top: 30px;
					text-transform: none;
					font-size: 14px;
				}
			}

			&__error {
				margin: 30px 0 -20px;
				text-align: center;
			}

			&--hide {
				.creativeBuild__title, .creativeBuild__content {
					display: none;
				}
				.creativeBuild__toggler {
					display: flex;
				}
			}
		}
	}

	&__submit {
		flex-shrink: 0;
		width: 100%;
		max-width: 300px;
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #003c5d;
		border-radius: 3px;
		font: normal bold 16px "Open Sans";
		text-transform: initial;
		color: #fff;

		&:hover, &:active, &:focus {
			background-color: #2674a8;
		}
	}

	&__updateDefaultAdunitCheckbox {
		display: block;
		user-select: none;
		.MuiIconButton-label {
			background: #eee;
		}
	}

	&--new {
		.campaignDetailsScreen__top {
			display: block;
		}
	}
	&--no-aub {
		.creativeBuild,
		.creativePreview__title,
		.pills,
		.campaignDetailsScreen__submit {
			display: none;
		}
		.packageCreative {
			margin-top: 20px;
			align-items: center;
			flex-direction: column;
		}
	}

	@include size(xl) {
		.landingUrl {
			width: auto;
			margin: 30px 0 0;
			align-self: center;
			&__label {
				display: none;
			}
		}
		.packageCreative {
			flex-direction: column;
			align-items: center;

			.creativePreview {

			}
			.creativeBuild {
				margin-top: 50px;
				margin-left: 0;

				&__title {
					text-align: center;
				}
				&__assets {
					margin-top: 20px !important;
				}

				&__toggler {
					flex-direction: column;
					align-items: center;
					&Text {
						margin: 0;
					}
					&Button {
						margin: 40px 0 0;
					}
				}
			}
		}
	}
	@include size(m) {
		.packageDetails {
			flex-direction: column;

			.packageInfo {
				margin: 20px 0 0;

				.checklist {
					&__item {
						margin: 12px 0;
						font-size: 14px;
						background-position: left 2px;
					}
				}
			}
		}
	}
	@include size(s) {
		.packageDetails {
			flex-direction: column;
		}
		.packageCreative {
			.creativeBuild {
				&__assets {
					flex-direction: column;
					.imageUpload {
						margin: 0 0 20px 0 !important;

						&__content {
							width: auto;
						}
					}
				}
			}
		}
	}
}