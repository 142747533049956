/* Media Queries */
@mixin size($range) {
	$size-s: 520px;
	$size-m: 800px;
	$size-l: 1024px;
	$size-xl: 1280px;
	$size-xxl: 1400px;

	@if $range == s {
		@media (max-width: #{$size-s}) { @content; }
	} @else if $range == m {
		@media (max-width: $size-m) { @content; }
	} @else if $range == l {
		@media (max-width: $size-l)  { @content; }
	} @else if $range == xl {
		@media (max-width: $size-xl)  { @content; }
	} @else if $range == xxl {
		@media (min-width: $size-xl) { @content; }
	}
}

@mixin retina($path, $ext: "png", $w: auto, $h: auto, $pos: center center, $repeat: no-repeat) {
	$at1x_path: "#{$path}.#{$ext}";
	$at2x_path: "#{$path}@2x.#{$ext}";

	background-image: url("#{$at1x_path}");
	background-size: $w $h;
	background-position: $pos;
	background-repeat: $repeat;

	@media
	all and (-webkit-min-device-pixel-ratio : 1.5),
	all and (-o-min-device-pixel-ratio: 3/2),
	all and (min--moz-device-pixel-ratio: 1.5),
	all and (min-device-pixel-ratio: 1.5) {
		background-image: url("#{$at2x_path}");
	}
}

@mixin multipleEllipsis($maxLines: 2, $lineHeight: 16px) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $maxLines;
	max-height: $maxLines * $lineHeight;
	overflow: hidden;
	line-height: $lineHeight;
}