html, body, :root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	color: #646464;
	background-color: #fff;
	font-size: 62.5%;
}
body {
	font-family: 'Open Sans', Roboto, 'Helvetica Neue',	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	flex-direction: column;
	margin: auto;
	font-size: 1.6rem;
}
.screen {
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
	overflow-y: auto;

	&__content {
		width: 100%;
		max-width: $maxWidth;
		display: flex;
		flex-direction: column;
		flex: none;
		padding: 1.5rem 3rem;
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 40rem;
		margin: 0 auto;


	}

	&__footer {
		margin-top: 20px;
		text-align: center;
		width: 100%;
	}
}
.MuiTooltip-popper {
	.MuiTooltip-tooltip {
		font-size: 12px;
	}
}