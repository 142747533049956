.checkbox {
	display: block;
	position: relative;
	height: 16px;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
}
.checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: transparent;
	border: 1px solid #2165a3;
}
.checkbox:hover input ~ .checkmark {
	background-color: rgba(0,0,0,0.05);
}

.checkbox input:checked ~ .checkmark {
	background-color: #2165a3;
}
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.checkbox input:checked ~ .checkmark:after {
	display: block;
}
.checkbox .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}