$imagesPath: "../images";
$fontsPath: "../fonts";

// Sizes
$minWidth: 32rem; // Layout Minimum Width
$headerHeight: 6rem; // Main Header Height
$maxWidth: 120rem;

// Variables
$defaultFont: normal 400 14px/18px "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
$imagesPath: "../../images";

// Colors
$white: #fff;
$black: #000;
$red: #a10000;
$green: #29a826;
$greenFilter: invert(50%) sepia(19%) saturate(2519%) hue-rotate(74deg) brightness(99%) contrast(84%);
$darkgray: #333;
$darkgrayFilter: invert(23%) sepia(44%) saturate(0%) hue-rotate(183deg) brightness(90%) contrast(109%);
$redFilter: invert(8%) sepia(91%) saturate(4813%) hue-rotate(5deg) brightness(97%) contrast(110%);
$primary: #2674a8;
$primaryFilter: invert(34%) sepia(15%) saturate(7064%) hue-rotate(182deg) brightness(90%) contrast(70%);
$secondary: #6a25f2;
$secondaryFilter: invert(31%) sepia(97%) saturate(7490%) hue-rotate(261deg) brightness(95%) contrast(100%);
$gpsColor: #52b9a8;
$maximumFootfallColor: #d74b8c;
$otsColor: #ee9961;
$visitsColor: #f08791;
$transactionsColor: #5f8ca0;
$bookedColor: #41b4d2;