.button	{
	width: 100%;
	max-width: 320px;
	height: 54px;
	color: #177fb3;
	font: normal 500 16px "Roboto";
	border-radius: 4px;
	outline: none;
	border: 1px solid #177fb3;
	background: #fff;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	&:active, &:focus {
		background-color: #003c5d;
		color: #fff;
	}

	&::after {
		content: "";
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&[disabled] {
		border: 1px solid #ccc;
		background-image: linear-gradient(to right, #ccc, #aaa);
		cursor: default;
		color: #fff;
	}

	&--primary {
		background-color: $primary;
		border: none;
		color: #fff;
	}

	&--gradient1 {
		background-image: linear-gradient(to right, #71afce, #3889e0);
		color: #fff;
	}
	&--gradient2 {
		background-image: linear-gradient(to right, #6864d1, #2496e5);
		color: #fff;
	}
	&--red, &--red:visited {
		background: $red;
		border-color: darken($red, 5);
		color: #fff;

		&:hover, &:active,  &:focus {
			background: lighten($red, 5);
		}
	}
	&--animate {
		&::after {
			display: block;
			animation: signal 2s infinite;
		}
	}
}