.checklist {
	margin-top: 20px;
	&__item {
		padding-left: 24px;
		margin-top: 14px;
		font-size: 14px;
		color: #2165a3;
		background: url('../../images/icons/checkbox.png') no-repeat left 3px;
		background-size: auto 14px;

		&--inactive {
			color: #b3b3b3;
			background-image: url('../../images/icons/checkbox-gray.png');
		}
	}

	&--large {
		.checklist__item {
			margin-top: 25px;
			font-size: 18px;
		}
	}
}