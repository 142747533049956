@keyframes pulse {
	from {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes halfPulse {
	from {
		opacity: 0.4;
	}
	50% {
		opacity: 1;
	}
	to {
		opacity: 0.4;
	}
}
@keyframes signal {
	from {
		box-shadow: 0 0 0 0 lighten($primary, 40);
	}
	70% {
		box-shadow: 0 0 0 20px rgba(0,0,0,0);
		border-radius: 10px;
	}
}
@keyframes graySignal {
	from {
		box-shadow: 0 0 0 0 #dfdfdf;
	}
	70% {
		box-shadow: 0 0 0 20px rgba(0,0,0,0);
		border-radius: 10px;
	}
}