/* Elements */
strong {
	font-weight: bold;
}
.error {
	color: $red;
	font: normal normal 14px Roboto;
}

/* Global Classes */
.link, .link:visited {
	color: #287eb0;
	font-weight: 600;
	text-decoration: none;

	&:hover, &:active {
		color: lighten(#287eb0, 10%);
	}
}
.generalError {
	margin: 10px;
}
.align-center {
	text-align: center;
}
.align-right {
	text-align: right;
}
.hide {
	display: none !important;
}
.MuiPopover-root {
	background: rgba(0,0,0,0.4);
	z-index: 9999;
}
.MuiTooltip-popper {
	z-index: 999;
}
.text-uppercase {
	text-transform: uppercase;
}

.MuiFormHelperText-root.MuiFormHelperText-contained {
	color: #777;
	font-size: 12px;
}