.header {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.075);
  z-index: 999;

  &__content {
    max-width: $maxWidth;
    width: 100%;
    padding: 0 40px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    align-items: center;

    .header__logo {
		visibility: hidden;
      width: 139px;
      height: 24px;
      background: url("../../images/logo.png") no-repeat center center; 
      background-size: contain;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

	  &--show {
		visibility: visible;
	  }
	  &--orb {
		background-image: url("../../images/logo-orb.png");
		width: 139px;
		height: 74px;
	  }
	  &--expo {
		background-image: url("../../images/logo-adgomobile.svg");
		width: 139px;
		height: 74px;
	  }
    }
    .header__locationDropdown {
      margin-left: 40px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--menu {
      .header__user::after {
        transform: rotate(180deg);
      }
      .header__userMenu.header__userMenu {
        display: flex;
      }
    }
    &--lang {
      .header__lang::after {
        transform: rotate(180deg);
      }
      .header__langMenu.header__langMenu {
        display: flex;
      }
    }
  }
  &__user {
    padding-left: 22px;
    position: relative;
    margin-right: 60px;
    font: normal 500 14px Roboto;
    background: url("../../images/icons/user.png") no-repeat left center;
    background-size: 14px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 2px;
      right: -24px;
      background: url("#{$imagesPath}/icons/solid-arrow.svg") no-repeat center;
      background-size: contain;
    }
  }

  .header__userMenu {
    display: none;
    flex-direction: column;
    width: 200px;
    position: absolute;
    right: -10px;
    top: 50px;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;

    li {
      padding: 8px 16px;
      color: #777;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #f7f7f7;
      }
    }
  }

  &__lang {
    width: 18px;
    height: 18px;
    position: relative;
    font: normal 500 14px Roboto;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    &--en {
      background-image: url("#{$imagesPath}/icons/flag-en.svg");
    }
    &--de {
      background-image: url("#{$imagesPath}/icons/flag-de.svg");
    }
    &--fr {
      background-image: url("#{$imagesPath}/icons/flag-fr.svg");
    }
    &--nl {
      background-image: url("#{$imagesPath}/icons/flag-nl.svg");
    }

    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 3px;
      right: -20px;
      background: url("#{$imagesPath}/icons/solid-arrow.svg") no-repeat center;
      background-size: contain;
    }
  }
  .header__langMenu {
    display: none;
    flex-direction: column;
    width: 80px;
    position: absolute;
    right: -10px;
    top: 50px;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;

    &Item {
      padding: 8px 16px 8px 42px;
      color: #777;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      background-position: 8px center;
      background-repeat: no-repeat;
      background-size: 26px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &--en {
        background-image: url("#{$imagesPath}/icons/flag-en.svg");
      }
      &--de {
        background-image: url("#{$imagesPath}/icons/flag-de.svg");
      }
      &--fr {
        background-image: url("#{$imagesPath}/icons/flag-fr.svg");
      }
      &--nl {
        background-image: url("#{$imagesPath}/icons/flag-nl.svg");
      }
    }
  }

  &__nav {
    margin-right: 30px;
    a {
      color: #333;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      font-family: "Roboto";
      font-weight: 500;
    }
  }
  &__logout {
    width: 32px;
    height: 32px;
    padding: 5px;
    margin-left: 10px;
    background: url("#{$imagesPath}/icons/logout.svg") no-repeat center center;
    background-size: 16px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      filter: brightness(1.5) contrast(0.5);
    }
  }

  @include size(m) {
    & & {
      &__left {
        flex-direction: column;
        .dropdown {
          margin-bottom: -10px;
        }
      }
      &__locationDropdown {
        margin-left: 0;
      }
    }
  }

  @include size(s) {
    & & {
      &__content {
        padding: 0 10px;
      }
      &__left {
        flex-direction: column;
      }
      &__locationDropdown {
        margin-left: 0;
      }
    }
  }
}
