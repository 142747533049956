.landingScreen {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: auto;

	.screen__content {
		flex-direction: row;
		padding-top: 10vh;
	}

	.landingScreen__listHeading {
		margin-top: 40px;
		color: #313131;
		font-size: 12px;
		font-family: "Open Sans";
		font-weight: bold;
		text-transform: uppercase;
	}


	&__info {
		width: 40%;
		margin: auto;
		h1 {
			font: normal bold 34px/34px "Open Sans";
			color: #313131;
		}


		ul {
			margin-top: 10px;
			padding-left: 30px;
			li {
				font: normal normal 16px "Open Sans";
				margin: 6px 0;
				list-style-type: disc;
			}
		}
		input {
			margin-top: 30px;
			margin-bottom: 20px;
			flex-shrink: 0;
		}
	}

	&__background {
		flex: 1;
		min-width: 320px;
		width: 60%;
		min-height: 30rem;
		margin-left: 40px;
		background: url('../../images/landing-background.jpg') no-repeat center center;
		background-size: contain;
	}

	@include size(l) {

		.screen__content {
			align-items: center;
			flex-direction: column-reverse;
		}

		&__info, &__background {
			width: 100%;
			max-width: 600px;
		}
		&__background {
			background-image: url('../../images/landing-background-medium.jpg');
		}
		&__info {
			text-align: center;
			margin-top: 30px;
			h1 {
				font-size: 30px;
			}
			ul {
				text-align: left;
			}
		}

		.button {
			margin-bottom: 20px;
		}
	}
	@include size(m) {
		flex-direction: column-reverse;
		align-items: center;

		& &__listHeading {
			margin-top: 20px;
		}

		&__info, &__background {
			width: 100%;
			max-width: 500px;
		}
		&__background {
			background-image: url('../../images/landing-background-small.jpg');
		}
		&__info {
			text-align: center;
			h1 {
				font-size: 20px;
				line-height: 24px;
			}
			ul {
				text-align: left;

				li {
					font-size: 14px;
				}
			}
		}
	}
	@include size(s) {
		flex-direction: column-reverse;
		align-items: center;

		& &__listHeading {
			margin-top: 20px;
			font-size: 12px;
			line-height: 14px;
		}

		&__info, &__background {
			width: 100%;
			max-width: 400px;

		}
		&__info {
			text-align: center;
			h1 {
				font-size: 18px;
				line-height: 20px;
			}
			ul {
				text-align: left;

				li {
					font-size: 12px;
				}
			}
		}
	}
}
