.weather {
	display: flex;
	align-items: center;
	width: stretch;
	&__label {
		width: 100px;
		height: 110px;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		position: relative;
		color: #0066a1;
		text-align: center;
		font: normal 600 14px "Open Sans";
		border-right: 1px solid #f2f2f2;
	}
	&__data {
		display: flex;
		align-items: center;
		width: calc(100% - 150px);
		&Item {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;
			width: 100%;
			height: 110px;
			padding-bottom: 16px;
			border-right: 1px solid #f2f2f2;
			color: #0066a1;
			font: normal bold 26px "Open Sans";
			background-repeat: no-repeat;
			background-position: center 20%;
			background-image: url('../../images/icons/weather-clear.png');

			&--clear { background-image: url('../../images/icons/weather-clear.png') }
			&--rain { background-image: url('../../images/icons/weather-rain.png') }
			&--snow { background-image: url('../../images/icons/weather-snow.png') }
			&--sleet { background-image: url('../../images/icons/weather-sleet.png') }
			&--cloudy { background-image: url('../../images/icons/weather-cloudy.png') }
			&--wind { background-image: url('../../images/icons/weather-wind.png') }
			&--placeholder {
				background-image: url('../../images/icons/weather-cloudy.png');
				opacity: 0.2;
				span {
					display: none;
				}
			}
		}
	}

	&__loader {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 2px;
		right: 4px;
	}
	&--load {
		.weather {
			&__loader {
				display: flex;
			}
			&__label, &__data {
				opacity: 0.5;
			}
		}
	}
	&--paddingRight {
		.weather__data {
			width: calc(100% - 200px);
		}
	}

	@include size(m) {
		&__dataItem {
			&:nth-of-type(3n) {
				display: none;
			}
		}
	}
	@include size(s) {
		&__dataItem {
			&:nth-of-type(3n) {
				display: flex;
			}
			&:nth-of-type(2n) {
				display: none;
			}
		}
	}
}