.sensors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .sensorSelector {
    width: 105px;
    height: 100px;
    position: relative;
    margin: 10px;
    margin: 10px 10px 16px;
    background: #fff;
    flex-shrink: 0;

    label {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center 18%;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      span {
        position: absolute;
        bottom: 7%;
        left: 0;
        width: 100%;
        padding: 0 5px;
        text-align: center;
      }
    }

    &__helpIcon {
      display: none;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 6px;
      padding-left: 1px;
      font-weight: bold;
      font-size: 11px;
      text-align: center;
      line-height: 16px;
      background: #fff;
      color: #333;
      border-radius: 50%;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        + .sensorSelector__helpContent {
          display: flex;
        }
      }
    }
    &__helpContent {
      display: none;
      justify-content: center;
      align-items: center;
      width: 180px;
      position: absolute;
      top: 25px;
      left: -78px;
      padding: 8px;
      font-size: 12px;
      line-height: 14px;
      background: #fff;
      color: #494949;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      z-index: 99;
    }

    input {
      display: none;
    }

    input:checked + label {
      box-shadow: 1px 4px 5px rgba(118, 177, 211, 0.3);
      span {
        color: #fff;
      }
    }

    &:hover {
      .sensorSelector__helpIcon {
        display: block;
      }
    }

    &--gps {
      label {
        background-image: url("../../images/icons/gps.png");
        span {
          color: #52b9a8;
          font: normal bold 24px "Open Sans";
        }
      }
      input:checked + label {
        background-color: #52b9a8;
      }
      .sensorSelector__helpIcon {
        background-color: #ddf9f4;
        color: #53baa9;
      }
      z-index: 10;
    }
    &--maxFootfall {
      label {
        background-image: url("../../images/icons/max-footfall.png");
        span {
          font: normal bold 13px/16px "Open Sans";
          color: #d74b8c;
        }

        .lang-nl & {
          span {
            font-size: 11px;
          }
        }
      }
      input:checked + label {
        background-color: #d74b8c;
      }
      .sensorSelector__helpIcon {
        background-color: #fca1cb;
        color: #d74c8c;
      }
      z-index: 11;
    }
    &--ots {
      label {
        background-image: url("../../images/icons/ots.png");
        span {
          bottom: 14%;
          font: normal bold 24px/16px "Open Sans";
          color: #ee9961;
        }
      }
      input:checked + label {
        background-color: #ee9961;
      }
      .sensorSelector__helpIcon {
        background-color: #fed0b1;
        color: #ee9a62;
      }
      z-index: 12;
    }
    &--visits {
      label {
        background-image: url("../../images/icons/visit.png");
        span {
          bottom: 11%;
          font: normal bold 16px "Open Sans";
          color: #f08791;
        }
      }
      input:checked + label {
        background-color: #f08791;
      }
      .sensorSelector__helpIcon {
        background-color: #fdcdd2;
        color: #f08791;
      }
      z-index: 13;
    }
    &--transactions {
      label {
        background-image: url("../../images/icons/transaction.png");
        span {
          bottom: 12%;
          font: normal bold 14px "Open Sans";
          color: #5f8ca0;
        }
      }
      input:checked + label {
        background-color: #5f8ca0;
      }
      .sensorSelector__helpIcon {
        background-color: #91dafa;
        color: #5f8ca0;
      }
      z-index: 14;
    }
    &--booked {
      label {
        background-image: url("../../images/icons/booked.png");
        span {
          bottom: 10%;
          font: normal bold 18px "Open Sans";
          color: #41b4d2;
        }
      }
      input:checked + label {
        background-color: #41b4d2;
      }
      .sensorSelector__helpIcon {
        background-color: #ccf4ff;
        color: #52bcd7;
      }
      z-index: 15;
    }
    &--impressions {
      display: none;
      label {
        background-image: url("../../images/icons/impressions.png");
        span {
          bottom: 10%;
          font: normal bold 18px "Open Sans";
          color: #7ec859;
        }
      }
      input:checked + label {
        background-color: #7ec859;
      }
      .sensorSelector__helpIcon {
        background-color: #d5ffbe;
        color: #7ec859;
      }
      z-index: 15;
    }
    &--clicks {
      display: none;
      label {
        background-image: url("../../images/icons/clicks.png");
        span {
          bottom: 12%;
          font: normal bold 13px "Open Sans";
          color: #6770d6;
        }
        .lang-nl & {
          span {
            font-size: 16px;
            bottom: 10%;
          }
        }
      }
      input:checked + label {
        background-color: #6770d6;
      }
      .sensorSelector__helpIcon {
        background-color: #cacffe;
        color: #6770d6;
      }
      z-index: 15;
    }
    &--disabled {
      filter: saturate(0.3);
      label {
        opacity: 0.3;
      }
      .sensorSelector__helpIcon {
        opacity: 0.6;
      }

      .sensorSelector__helpIcon,
      > label {
        cursor: default;
      }
    }
	&--hidden {
		display: none !important;
	}
  }
  &--campaign-stats {
    .sensorSelector--impressions,
    .sensorSelector--clicks {
      display: block;
    }
  }

  &--hide-transactions {
    .sensorSelector--transactions,
    .sensorSelector--booked {
      display: none;
    }
  }

  &--one-column {
	  align-items: center;
	  flex-direction: column;
	  justify-content: flex-start;
  }
}
