.lineChart {
	height: 400px;
	min-width: 300px;
	min-height: 200px;
	background: #f4fbff;
	position: relative;
	box-shadow: inset 0 15px 8px 0 rgba(51, 153, 255, 0.05),
		inset 0 -15px 8px 0 rgba(51, 153, 255, 0.05);

	> div:first-child {
		position: relative;
		z-index: 9;
	}

	&::before,
	&::after,
	> div::before {
		content: "";
		display: block;
		background: #d4dadc;
		z-index: 0;
	}
	&::before {
		width: 2px;
		height: calc(100% - 61px);
		position: absolute;
		top: 22px;
		right: 48px;
	}
	&::after {
		width: calc(100% - 148px);
		height: 2px;
		position: absolute;
		top: 22px;
		left: 99px;
		background-color: #d4dadc;
	}

	> div::before {
		width: 2px;
		height: calc(100% - 61px);
		position: absolute;
		top: 22px;
		left: 99px;
	}

	// Chart interactive popup container
	div div > div {
		width: auto;
		height: auto;
	}

	.customLeftAxisTick {
		width: 60px;
		height: 20px;
		font: normal normal 14px "Open Sans";
		text-align: right;
		padding-right: 5px;

		svg {
			display: none;
			transform: translate(-30px, -20px);
		}
	}

	.customRightAxisTick {
		width: 80px;
		height: 20px;
		font: normal normal 13px Roboto;
		letter-spacing: -1px;
		color: #41b4d2;
		text-align: left;
		padding-right: 5px;

		svg {
			display: none;
			transform: translate(-30px, -20px);
		}
	}

	svg {
		foreignObject:first-child {
			.customLeftAxisTick {
				border-color: lighten(#b3b7b8, 2);
			}
		}

		g {
			g:not(:first-child) {
				line:first-child {
					stroke: #b3b7b8;
				}
			}
			// Right axis
			g:nth-of-type(3) {
				g {
					line {
						display: none;
					}
					text {
						transform: translate(6px, -10px);
						letter-spacing: -1px;
						fill: #41b4d2 !important;
						font-size: 13px !important;
					}
				}
			}
			// Bottom axis
			g:nth-of-type(4) {
				g {
					line {
						display: none;
					}
					text {
						//transform: translate(4px, -10px);
						fill: #c8d4db !important;
					}
				}
			}
			// Left axis
			g:nth-of-type(5) {
				g {
					line {
						display: none;
					}
					text {
						transform: translate(-6px, -10px);
					}
				}
			}
		}
	}

	&__update {
		padding: 4px 14px 4px 14px;
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(75%, -100%);
		color: #aaa;
		font-size: 11px;
		text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 2px #fff;
		border-radius: 4px;
		background: rgba(255, 255, 255, 0.6);
		opacity: 0;
		z-index: -1;
		text-transform: uppercase;
	}

	&--loading {
		svg {
			opacity: 0.5;
		}
		.lineChart__update {
			animation: halfPulse 1.2s infinite;
			z-index: 9;
		}
	}

	&--large {
		height: 491px;
	}

	&--paddingRight {
		&::after {
			width: calc(100% - 198px);
		}
		&::before {
			right: 98px;
		}
	}

	&--highlighted-gps {
		svg {
			g {
				g {
					path:not(.gps) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-maxFootfall {
		svg {
			g {
				g {
					path:not(.maxFootfall) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-ots {
		svg {
			g {
				g {
					path:not(.ots) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-visits {
		svg {
			g {
				g {
					path:not(.visits) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-transactions {
		svg {
			g {
				g {
					path:not(.transactions) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-booked {
		svg {
			g {
				g {
					path:not(.booked) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-impressions {
		svg {
			g {
				g {
					path:not(.impressions) {
						opacity: 0.5;
					}
				}
			}
		}
	}
	&--highlighted-clicks {
		svg {
			g {
				g {
					path:not(.clicks) {
						opacity: 0.5;
					}
				}
			}
		}
	}

	&--gps {
		svg {
			g {
				g {
					path:not(.gps) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--maxFootfall {
		svg {
			g {
				g {
					path:not(.maxFootfall) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--ots {
		svg {
			g {
				g {
					path:not(.ots) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--visits {
		svg {
			g {
				g {
					path:not(.visits) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--transactions {
		svg {
			g {
				g {
					path:not(.transactions) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--booked {
		svg {
			g {
				g {
					path:not(.booked) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--impressions {
		svg {
			g {
				g {
					path:not(.impressions) {
						opacity: 0.1;
					}
				}
			}
		}
	}
	&--clicks {
		svg {
			g {
				g {
					path:not(.clicks) {
						opacity: 0.1;
					}
				}
			}
		}
	}
}
.customBottomAxisTick {
	font-size: 14px;
	color: #c8d4db;
}
.customTooltip {
	padding: 12px 15px;
	min-width: 150px;
	color: #fff;
	border: 1px solid transparent;
	font-size: 14px;
	z-index: 99999;

	&::after,
	&::before {
		top: 100%;
		left: 50%;
		margin-top: -1px;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		z-index: 99;
	}

	&::after {
		border-color: rgba(101, 136, 158, 0);
		border-width: 7px;
		margin-left: -7px;
	}
	&::before {
		border-color: rgba(204, 204, 204, 0);
		border-top-color: transparent;
		border-width: 8px;
		margin-left: -8px;
	}

	&__title {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 2px 0 2px 30px;
		font-size: 14px;
		background-position: center left;
		background-repeat: no-repeat;
		background-size: contain;
	}
	&__yValue {
		margin-top: 10px;
		font-weight: bold;
		font-size: 18px;
	}
	&__xValue {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		font-size: 12px;
		font-weight: bold;

		&Right {
			padding-left: 20px;
		}
	}
	&.gps {
		border-color: darken($gpsColor, 10);
		.customTooltip__title {
			font-size: 16px;
			background-image: url("../../images/icons/gps.png");
		}
		&::before {
			border-top-color: darken($gpsColor, 10);
		}
		&::after {
			border-top-color: $gpsColor;
		}
	}
	&.maxFootfall {
		border-color: darken($maximumFootfallColor, 10);
		.customTooltip__title {
			background-image: url("../../images/icons/max-footfall.png");
		}
		&::before {
			border-top-color: darken($maximumFootfallColor, 10);
		}
		&::after {
			border-top-color: $maximumFootfallColor;
		}
	}
	&.ots {
		border-color: darken($otsColor, 10);
		.customTooltip__title {
			font-size: 16px;
			background-image: url("../../images/icons/ots.png");
		}
		&::before {
			border-top-color: darken($otsColor, 10);
		}
		&::after {
			border-top-color: $otsColor;
		}
	}
	&.visits {
		border-color: darken($visitsColor, 10);
		.customTooltip__title {
			background-image: url("../../images/icons/visit.png");
		}
		&::before {
			border-top-color: darken($visitsColor, 10);
		}
		&::after {
			border-top-color: $visitsColor;
		}
	}
	&.transactions {
		border-color: darken($transactionsColor, 10);
		.customTooltip__title {
			font-size: 16px;
			background-image: url("../../images/icons/transaction.png");
		}
		&::before {
			border-top-color: darken($transactionsColor, 10);
		}
		&::after {
			border-top-color: $transactionsColor;
		}
	}
	&.booked {
		border-color: darken($bookedColor, 10);
		.customTooltip__title {
			background-image: url("../../images/icons/booked.png");
		}
		&::before {
			border-top-color: darken($bookedColor, 10);
		}
		&::after {
			border-top-color: $bookedColor;
		}
	}
}
