@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Medium.eot');
	src: url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Roboto-Medium.ttf') format('truetype');
	//url('../../fonts/Roboto-Medium.woff2') format('woff2'),
	//url('../../fonts/Roboto-Medium.woff') format('woff'),
	//url('../../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Bold.eot');
	src: url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Roboto-Bold.ttf') format('truetype');
	//url('../../fonts/Roboto-Bold.woff2') format('woff2'),
	//url('../../fonts/Roboto-Bold.woff') format('woff'),
	//url('../../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Regular.eot');
	src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Roboto-Regular.ttf') format('truetype');
	//url('../../fonts/Roboto-Regular.woff2') format('woff2'),
	//url('../../fonts/Roboto-Regular.woff') format('woff'),
	//url('../../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-Bold.eot');
	src: url('../../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/OpenSans-Bold.ttf') format('truetype');
	//url('../../fonts/OpenSans-Bold.woff2') format('woff2'),
	//url('../../fonts/OpenSans-Bold.woff') format('woff'),
	//url('../../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Open Sans';
	src: url('../../fonts/OpenSans-Regular.eot');
	src: url('../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/OpenSans-Regular.ttf') format('truetype');
	//url('../../fonts/OpenSans-Regular.woff2') format('woff2'),
	//url('../../fonts/OpenSans-Regular.woff') format('woff'),
	//url('../../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}
