.congratsScreen {
	align-items: center;
	justify-content: center;
	.screen__content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__guarantee {
		width: 100%;
		height: 120px;
		background: url('../../images/icons/3days-guarantee.png') no-repeat center;
		background-size: contain;
	}
	&__bg {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
		height: 100%;
		max-height: 400px;
		margin-top: 20px;
		background: url('../../images/congrats.jpg') no-repeat center center;
		background-size: contain;
	}
	&__congratsText {
		margin-top: 40px;
		font: normal bold 40px/40px "Open Sans";
		color: #7f2aff;
	}
	&__thanksText {
		font: normal bold 20px "Open Sans";
		color: #7f2aff;
	}
	&__note {
		color: #a7a7a7;
		font-family: "Open Sans";
		text-align: center;
		font-size: 14px;
		max-width: 500px;
		padding: 10px 30px 0;
		margin-top: 20px;
		border-top: 1px solid #ccc;
	}
	.button {
		margin-top: 20px;
	}
	&__package {
		height: 160px;
		width: 250px;
		margin-top: 20px;
		border-radius: 100px;
		text-align: center;
		background-color: #fff;
		background-repeat: no-repeat;
		background-position: center 5px;
		background-size: 70px;

		&Title {
			margin-top: 90px;
			font-size: 28px;
		}
		&Subtitle {
			margin-top: 8px;
			font-size: 16px;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}

	&--small {
		.congratsScreen__package {
			background-image: url('../../images/icons/package-small.png');
		}
	}
	&--medium {
		.congratsScreen__package {
			background-image: url('../../images/icons/package-medium.png');
		}
	}
	&--large {
		.congratsScreen__package {
			background-image: url('../../images/icons/package-large.png');
		}
	}

	@media (max-width: 900px) {
		width: 800px;

		.congratsScreen {
			&__congratsText {
				margin-top: 60px;
				font-size: 34px;
			}
			&__thanksText {
				font-size: 17px;
			}
			&__package {
				height: 120px;
				width: 230px;
				background-size: 50px;

				&Title {
					margin-top: 70px;
					font-size: 22px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 12px;
				}
			}
		}
	}
	@media (max-width: 800px) {
		width: 700px;
		.congratsScreen {
			&__congratsText {
				margin-top: 80px;
				font-size: 30px;
			}
			&__thanksText {
				font-size: 16px;
			}
			&__package {
				height: 100px;
				width: 200px;
				background-size: 40px;

				&Title {
					margin-top: 50px;
					font-size: 20px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 12px;
				}
			}
		}
	}
	@media (max-width: 700px) {
		width: 600px;
		.congratsScreen {
			&__congratsText {
				margin-top: 80px;
				font-size: 24px;
			}
			&__thanksText {
				font-size: 14px;
			}
			&__package {
				height: 100px;
				width: 200px;
				background-size: 40px;

				&Title {
					margin-top: 50px;
					font-size: 20px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 10px;
				}
			}
		}
	}
	@media (max-width: 600px) {
		width: 500px;
		.congratsScreen {
			&__congratsText {
				margin-top: 120px;
				font-size: 20px;
				line-height: 20px;
			}
			&__thanksText {
				margin-top: 0;
				font-size: 11px;
			}
			&__package {
				height: 60px;
				width: 120px;
				background-size: 30px;
				background-position: center 2px;

				&Title {
					margin-top: 34px;
					font-size: 15px;
					line-height: 10px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 9px;
				}
			}
		}
	}
	@media (max-width: 500px) {
		width: 400px;
		.congratsScreen {
			&__congratsText {
				margin-top: 130px;
				font-size: 14px;
				line-height: 20px;
			}
			&__thanksText {
				margin-top: 0;
				font-size: 9px;
			}
			&__package {
				height: 60px;
				width: 120px;
				background-size: 30px;
				background-position: center 2px;

				&Title {
					margin-top: 34px;
					font-size: 14px;
					line-height: 10px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 8px;
				}
			}
		}
	}
	@media (max-width: 400px) {
		width: 320px;
		.congratsScreen {
			&__congratsText {
				margin-top: 140px;
				font-size: 11px;
				line-height: 20px;
			}
			&__thanksText {
				margin-top: 0;
				font-size: 8px;
			}
			&__package {
				height: 60px;
				width: 120px;
				background-size: 30px;
				background-position: center 2px;

				&Title {
					margin-top: 34px;
					font-size: 14px;
					line-height: 10px;
				}
				&Subtitle {
					margin-top: 0;
					font-size: 8px;
				}
			}
		}
	}
}